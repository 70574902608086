import React from "react"
import { 
    EmailShareButton, 
    EmailIcon,
    TwitterShareButton, 
    TwitterIcon
} from "react-share"

export default function ShareBlock({ url, title, siteName }) {
    const shareTitle = `${title} - ${siteName}`
    return (
        <div className="share-block">
            <div className="h4 d-block text-center">Share Link</div>
            <div className="d-block text-center">
                <EmailShareButton className="p-1" url={url} subject={shareTitle}>
                    <EmailIcon size={40} round />
                </EmailShareButton>
                <TwitterShareButton className="p-1" url={url} title={shareTitle}>
                    <TwitterIcon size={40} round />
                </TwitterShareButton>
            </div>
        </div>
    )
}

ShareBlock.defaultProps = {
    url: "https://mywebsite.com/page-to-share/",
    title: "",
    siteName: ""
}