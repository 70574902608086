import React from "react"
import { graphql } from "gatsby"

// Layout
import Layout from "../layouts/layout"
import "./blog-post.css"
import "./codeblock.css"
import "katex/dist/katex.min.css"

// Component
import { getTechTags } from "../components/techTag"
import ShareBlock from "../components/shareBlock"
import Breadcrumb from "../components/breadcrumb"

const BlogPost = (props) => {
    const siteMetadata = props.data.site.siteMetadata
    const post = props.data.markdownRemark
    const url = `${siteMetadata.siteUrl}${props.pageContext.slug}`
    return (
        <Layout title={post.frontmatter.title} adsense={true} path={props.location.pathname}>
            <div className="mt-3">
                <Breadcrumb title={post.frontmatter.title} />
            </div>
            <h1 className="title h2">{post.frontmatter.title}</h1>
            <span className="text-muted">最終更新日: {post.frontmatter.date}</span>
            <div className="">
                {getTechTags(post.frontmatter.tags, siteMetadata.labels)}
            </div>
            <div className="post-main text-break mt-3 me-md-3" dangerouslySetInnerHTML={{ __html: post.html }} />
            <div className="mb-3">
                <ShareBlock title={post.frontmatter.title} siteName={siteMetadata.title} url={url} />
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query pageQuery($slug: String!) {
        site {
            siteMetadata {
                title
                siteUrl
                labels {
                    tag
                    title
                    svg 
                    size 
                    color
                }
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "YYYY年M月D日")
                tags
            }
        }
    }
`

export default BlogPost