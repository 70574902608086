import React from "react"
import { Link } from "gatsby"

export default function Breadcrumb({ title }) {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-1" style={{flexWrap: "nowrap"}}>
                { title === "Home" ? 
                    <li className="breadcrumb-item active">Home</li> : 
                    <li className="breadcrumb-item"><Link className="text-dark" to="/">Home</Link></li>
                }
                { title === "Home" ? 
                    null : 
                    <li className="breadcrumb-item active text-truncate" aria-current="page">{title}</li>
                }
            </ol>
        </nav>
    )
}

Breadcrumb.defaultProps = {
    title: "",
}